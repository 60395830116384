<template>
  <div class="guideTop10_container">
    <itemScroll ref="itemScroll">
      <div class="guideTop10_container_blcok">
        <div class="guideTop10_title_container">
          <div class="guideTop10_title">十大指南</div>
          <div class="guideTop10_log">
            <img :src="require('@/assets/images/guideTop10/0.jpg')" />
          </div>
          <div class="guideTop10_banner" @click.stop="goToArticle(bannerUlr)">
            <div class="guideTop10_banner_img"></div>
            <p class="guideTop10_banner_text">
              Bingos精选：妇产科，生殖领域十大指南
            </p>
          </div>
        </div>
        <div class="guideTop10_content_container">
          <div class="guideTop10_content_title_block">
            <div class="guideTop10_content_title">十大指南</div>
            <!-- <div class="guideTop10_content_title_underline"></div> -->
          </div>
          <div class="guideTop10_content_list">
            <div
              class="guideTop10_content_item_block"
              v-for="(item, index) in dataList"
              :key="index"
              @click="goToArticle(item.url)"
            >
              <div class="guideTop10_content_item_title">
                <div class="guideTop10_content_item_title_text">
                  {{ item.title }}
                </div>
                <div class="guideTop10_content_item_author">
                  {{ item.author }}
                </div>
              </div>
              <div class="guideTop10_content_item_img">
                <img :src="item.img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </itemScroll>
  </div>
</template>
<script>
import itemScroll from "@/components/unit/itemScroll";
export default {
  data() {
    return {
      bannerUlr:
        "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489761&idx=2&sn=2badf9ae88d531ffdda218e81ea0062b&source=41#wechat_redirect",
      dataList: [
        {
          img: require("@/assets/images/guideTop10/1.jpg"),
          title: "十大精选指南解读之《2017 EAPM建议：早产和生育管理》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489766&idx=1&sn=e68cc1d3677a1a1e351808317849c91c&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
        {
          img: require("@/assets/images/guideTop10/2or5.jpg"),
          title: "十大精选指南解读之《ACOG剖宫产术后阴道分娩临床实践简报》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489767&idx=1&sn=cbe7946ce5b3dd18b532bbefe7754a0c&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
        {
          img: require("@/assets/images/guideTop10/3.jpg"),
          title: "十大精选指南解读之《高龄女性不孕诊治指南》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489768&idx=1&sn=e7ae925f11bd51fe6ae10110ce3a8eb7&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
        {
          img: require("@/assets/images/guideTop10/4.jpg"),
          title: "十大精选指南解读之：RCOG产后出血管理指南(预防部分)",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489769&idx=1&sn=96f74e5633b5c2cd5925d8b7ce37cdb9&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
        {
          img: require("@/assets/images/guideTop10/2or5.jpg"),
          title: "十大精选指南解读之《ACOG剖宫产术后阴道分娩临床实践简报》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489767&idx=1&sn=cbe7946ce5b3dd18b532bbefe7754a0c&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
        {
          img: require("@/assets/images/guideTop10/6.jpg"),
          title: "十大精选指南解读之《孕前和孕期保健指南（2018）》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489766&idx=2&sn=c6428865621583ff15c1d23d033fbb22&source=41#wechat_redirect",
          author: "",
        },
        {
          img: require("@/assets/images/guideTop10/7.jpg"),
          title: "十大精选指南解读之《乙型肝炎病毒母婴传播预防临床指南》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489765&idx=1&sn=3798354643d1f8ed2453619d480104cc&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
        {
          img: require("@/assets/images/guideTop10/8.jpg"),
          title: "十大精选指南解读之《妊娠剧吐的诊断及临床处理专家共识(2015)》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489764&idx=1&sn=2ee6bf7eada0ccb318a70ddf98a6e34c&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
        {
          img: require("@/assets/images/guideTop10/9.jpg"),
          title: "十大精选指南解读之《电子胎心监护应用专家共识》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489763&idx=1&sn=1b62d4f41cac376cb61c0ac85c77daa0&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
        {
          img: require("@/assets/images/guideTop10/10.jpg"),
          title: "十大精选指南解读之《妊娠合并糖尿病诊治指南》",
          url: "https://mp.weixin.qq.com/s?__biz=Mzk0MzM5MjIxOQ==&mid=2247489761&idx=1&sn=9b867a9a2bd3a454813df7fbea93fbe8&source=41#wechat_redirect",
          author: "原创:iSlide",
        },
      ],
    };
  },
  components: {
    itemScroll,
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "十大指南";
  },
  methods: {
    goToArticle(url) {
      window.location.href = url;
      // console.log(url)
    },
  },
};
</script>
<style>
.guideTop10_container {
  width: 100%;
  height: 100%;
  padding: 6vw 5vw;
  position: relative;
  overflow: hidden;
}
.guideTop10_container_blcok {
  width: 100%;
  height: 100%;
  /* display: flex;
    flex-direction: column; */
}
.guideTop10_title_container {
  width: 100%;
  flex: 73.8vw 0 0;
  position: relative;
}
.guideTop10_title {
  font-size: 5vw;
  /* font-weight: 600; */
}
.guideTop10_log {
  width: 7.2vw;
  height: 7.2vw;
  border-radius: 3.5vw;
  margin: 4vw 0;
}
.guideTop10_log img {
  width: 100%;
  height: auto;
  border-radius: 3.5vw;
}
.guideTop10_banner {
  width: 100%;
  height: 51.06vw;
  background: #f7f7f7;
}
.guideTop10_banner_img {
  width: 100%;
  height: 38.1vw;
  background-image: url("../../../assets/images/guideTop10/banner.jpg");
  position: relative;
  display: block;
  background-size: cover;
  background-position: 0 -25.5vw;
}
.guideTop10_banner_text {
  font-size: 4vw;
  padding: 4vw;
  display: flex;
  align-items: center;
}
.guideTop10_content_container {
  margin-top: 2vw;
  height: 100%;
  width: 100%;
}
.guideTop10_content_title_block {
  width: 100%;
  height: 15vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.guideTop10_content_title {
  height: 100%;
  color: #0ab50b;
  /* font-weight: 600; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.guideTop10_content_title_block .guideTop10_content_title::after {
  content: " ";
  display: block;
  width: 4vw;
  height: 1vw;
  background-color: #09bb07;
  position: absolute;
  bottom: 0;
  margin-bottom: -0.3vw;
  left: 50%;
  margin-left: -1.33vw;
  z-index: 1;
}
.guideTop10_content_title_underline {
  width: 4vw;
  border-bottom: #0ab50b 5px solid;
}
.guideTop10_content_list {
  width: 100%;
  overflow: hidden;
}
.guideTop10_content_item_block {
  border-top: #e7e7e7 1px solid;
  padding: 4vw 0;
  width: 100%;
  height: 28.4vw;
  display: flex;
}
.guideTop10_content_item_title {
  height: 100%;
  flex: 1 0 0;
  margin-right: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.guideTop10_content_item_img {
  height: 100%;
  flex: 25vw 0 0;
}
.guideTop10_content_item_img img {
  width: 100%;
  height: 100%;
}
.guideTop10_content_item_title_text {
  height: 10vw;
  width: 100%;
  line-height: 5vw;
  box-sizing: content-box;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.guideTop10_content_item_author {
  width: 100%;
  color: #999;
  font-size: 3.5vw;
}
</style>